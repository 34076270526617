













import Vue from "vue";
import { mapState } from "vuex";
import { UserSettings } from "@/store/types";

export default Vue.extend({
  name: "SettingsForm",
  data() {
    return {
      settings: null as UserSettings | null
    };
  },
  computed: {
    ...mapState("users", ["userSettings"])
  },
  mounted() {
    this.settings = { ...this.userSettings };
  },
  methods: {
    async saveSettings() {
      await this.$store.dispatch("users/updateUserSettings", {
        ...this.settings
      });
      this.$emit("close");
    }
  },
  validations() {
    return {
      settings: {}
    };
  }
});
